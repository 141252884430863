body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 0;
  padding: 0;
  background: radial-gradient(ellipse at top left, #e66465, transparent),
  radial-gradient(ellipse at bottom right, #0c3b9f, transparent);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

ol, ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin: 0 1em 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (orientation:landscape) {
  .game-board .square {
    height: 10vh;
    width: 10vh;
  }

  .demo-board .square {
    height: 8vh;
    width: 8vh;
  }
}

@media (orientation:portrait) {
  .game-board .square {
    height: 11vw;
    width: 11vw;
  }
  .demo-board .square {
    height: 10vw;
    width: 10vw;
  }
  h1 {
    font-size: 1.5em;
  }
}

.game {
  height: 100%;
  display: flex;
}

.square {
  background: #fff;
  border: none;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  padding: 0;
  text-align: center;
  outline: none;
  transition: 50ms linear;
  transition-property: background-color;
}

.white {
  background: rgba(255, 255, 255, 0.2);
}

.black {
  background: rgba(0, 0, 0, 0.2);
}

.square:hover {
  background: rgba(150, 150, 255, 0.1);
}

.held {
  background: rgba(150, 150, 255, 0.4);
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.status button {
  float: right;
}

.game-board {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.settings-buttons {
  margin-bottom: 1.5em;
}

.icon {
  opacity: 0.95;
  transition: 50ms linear;
  transition-property: opacity, margin-top;
}

.icon:hover {
  opacity: 1;
  margin-top: -0.35em;
}
