.popup-outer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}

.popup-inner p, .popup-inner ul {
  margin: 0 0 0.5em;
}

.popup-inner li {
  margin: 0;
}

.popup-inner h2 {
  font-size: 1em;
  margin-bottom: 0;
}

.big-input {
  font-size: 150%;
  padding: 0.5em;
  background-color: rgba(255, 255, 255, 0.1);
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.15);
  color: white;
  font-weight: bold;
}

.big-input[type="submit"] {
  padding-left: 1em;
  padding-right: 1em;
}
@media (orientation:portrait) {
  .big-input {
    font-size: 120%;
  }
  .popup-inner {
    left: 5%;
    right: 5%;
    top: 5%;
    bottom: 5%;
    text-align: justify;
    padding-left: 6%;
    padding-right: 6%;
    font-size: 1.2em;
    line-height: 150%;
  }
}

@media (orientation:landscape) {
  .popup-inner {
    left: 30%;
    right: 30%;
    top: 15%;
    bottom: 15%;
    padding-left: 3%;
    padding-right: 3%;
    font-size: 1.5em;
    line-height: 150%;
  }
}

.popup-inner {
  position: absolute;
  margin: auto;
  border-radius: 2px;
  background: #d5d5d5;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

.popup h1 {
  text-align: center;
}

.white-on-light {
  color: white;
  text-shadow: 2px 2px 2px #0003;
}